import $http from "./index";
import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;
// 获取退卡列表
export const returnList = (data) => {
  return $http.post(`/app/info/cardRefund/back/getReturnCardPage`, data);
};
// 获取退卡详情
export const returnDetail = (data) => {
  return $http.get(`/app/info/cardRefund/back/getReturnCardInfo`, { params: data });
};
// 通过
export const passReturn = (data) => {
  return $http.post(`/app/info/cardRefund/back/agree`, data);
};
// 取消
export const rejReturn = (data) => {
  return $http.post(`/app/info/cardRefund/back/cancel`, data);
};
// 导出
export const returnCardExport = (data) => {
  return $http.post(`/app/info/cardRefund/back/returnCardExport`, data);
};
// 获取门店树
export const shopDetail = (data) => {
  return $http.get('/app/info/cardRefund/shopCondition');
};


// 获取预约申请单列表
export const appointmentPageList = (data) => {
  return $http.post(`/app/info/appointment/back/queryAppointmentPageList`, data);
};
// 获取预约申请单列表 导出
export const returnAppointmentPageList = (data) => {
  return $http.post(`/app/info/appointment/back/appointmentListExport`, data);
};
// 申请单详情
export const appointmentDetail = (data) => {
  return $http.get(`/app/info/appointment/back/queryBackAppointmentDetail`, { params: data });
};