<template>
  <div class="returnCardBox">
    <!-- 筛选条件 -->
    <div class="searchBox">
      <el-row>
        <el-col :span="5" class="flex items-center">
          <div class="flex-none mr-8px">姓名:</div>
          <el-input v-model="queryParams.data.memberName" placeholder="请输入联系姓名" />
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">联系手机号:</div>
          <el-input v-model="queryParams.data.linkMobile" placeholder="请输入手机号" />
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">会员ID:</div>
          <el-input v-model="queryParams.data.memberId" placeholder="请输入会员ID" />
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">会员手机号:</div>
          <el-input v-model="queryParams.data.memberPhone" placeholder="请输入会员手机号" />
        </el-col>
      </el-row>
      <el-row class="mt20 mb-20px">
        <el-col :span="5" class="flex items-center">
          <div class="flex-none mr-8px">申请单状态:</div>
          <el-select v-model="queryParams.data.status" placeholder="请选择申请单状态">
            <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">预约单状态:</div>
          <el-select v-model="queryParams.data.smServeStatus" placeholder="请选择预约单状态">
            <el-option v-for="item in smServeStatusList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">预约单单号:</div>
          <el-input v-model="queryParams.data.appointmentId" placeholder="请输入预约单单号" />
        </el-col>
      </el-row>
      <el-row class="mt20 mb-20px">
        <el-col :span="6" class="flex items-center">
          <div class="flex-none mr-8px">提交时间:</div>
          <el-config-provider :locale="zhCn">
            <el-date-picker
              v-model="createTime"
              :default-time="[new Date('1 00:00:00'), new Date('1 23:59:59')]"
              end-placeholder="结束日期"
              start-placeholder="开始日期"
              type="daterange"
              value-format="YYYY-MM-DD HH:mm:ss"
              @change="timeChange"
            />
          </el-config-provider>
        </el-col>
        <el-col :span="10" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">预约门店:</div>
          <el-cascader :key="refreshItem" style="width: 100%;" v-model="shopValue" :options="options" @change="handleChange" :props="props" placeholder="请选择最近消费门店" collapse-tags filterable />
        </el-col>
        <el-col :span="2" class="ml-20px flex">
          <el-button type="primary" @click="getTabList">查 询</el-button>
          <el-button @click="resetTable">重 置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="exportBox">
      <el-button @click="exportExcel" icon="el-icon-download" type="primary" size="small">导 出</el-button>
    </div>
    <!-- table表格 -->
    <el-table stripe :data="appointmentList" :header-cell-style="{ background: 'rgb(242, 242, 242)', color: '#333' }" v-loading="tableLoading" element-loading-text="拼命加载中...">
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column prop="appointmentCode" label="申请单号" show-overflow-tooltip />
      <el-table-column prop="shopName" label="申请门店" show-overflow-tooltip />
      <el-table-column prop="memberName" label="姓名" show-overflow-tooltip />
      <el-table-column prop="memberPhone" label="会员手机号" />
      <el-table-column prop="memberId" label="会员ID" show-overflow-tooltip />
      <el-table-column prop="linkMobile" label="联系手机号" />
      <el-table-column prop="createTime" :formatter="formatCreationTime" label="提交时间" width="160" show-overflow-tooltip />
      <el-table-column label="申请单来源">
        <template #default="scope">
          <span v-if="scope.row.applySource == 1">小程序</span>
          <span v-if="scope.row.applySource == 2">店务</span>
        </template>
      </el-table-column>
      <el-table-column label="申请状态">
        <template #default="scope">
          <span v-if="scope.row.status == 0">待确认</span>
          <span v-if="scope.row.status == 1">已确认</span>
          <span v-if="scope.row.status == 2">已取消</span>
        </template>
      </el-table-column>
      <el-table-column prop="appointmentId" label="关联预约单" show-overflow-tooltip />
      <el-table-column label="预约单状态">
        <template #default="scope">
          <span v-if="scope.row.smServeStatus == 0">预约未到店</span>
          <span v-if="scope.row.smServeStatus == 1">已到店未服务</span>
          <span v-if="scope.row.smServeStatus == 2">服务中</span>
          <span v-if="scope.row.smServeStatus == 3">服务结束</span>
          <span v-if="scope.row.smServeStatus == 4">取消</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template #default="scope">
          <el-button type="primary" plain size="small" @click="lookDetailFun(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="paginationBox">
      <Pagination v-model:page="queryParams.data.page" v-model:size="queryParams.data.pageSize" :total="total" @pagination="getTabList" />
    </div>
  </div>
</template>

<script setup name="ReturnCard">
  import { ref, reactive, onMounted } from 'vue'
  import * as XLSX from "xlsx"
  import { ElMessage } from "element-plus"
  import { useRouter } from "vue-router"
  import { appointmentPageList, returnAppointmentPageList, shopDetail } from "@/http/returnCard"
  import { FullTimeFormat } from '@/utils/common'
  import Pagination from '@/components/pagination/index.vue'
  import zhCn from "element-plus/lib/locale/lang/zh-cn"

  const now = new Date();
  const date = now.toLocaleDateString().split('/');
  date[1] < 10 ? date[1] = `0${date[1]}` : date[1] = date[1]
  date[2] < 10 ? date[2] = `0${date[2]}` : date[2] = date[2]
  let createTime = ref([`${date[0]}-${date[1]}-${date[2]} 00:00:00`, `${date[0]}-${date[1]}-${date[2]} 23:59:59`])
  onMounted(() => {
    getTabList()
    getShopTree()
  })
  // 筛选条件
  let queryParams = reactive({
    data: {
      shopIds: [],
      linkMobile: '', // 联系手机号
      memberId: '', // 会员ID
      memberName: '', // 姓名
      memberName: '', // 联系姓名
      memberPhone: '', // 会员手机号
      status: null, // 申请单状态 0-未确认 1-已确认 2-已取消
      smServeStatus: null, // 预约单状态: 0-预约未到店,1-已到店未服务,2-服务中, 3-服务结束,4-取消
      appointmentId: '', // 预约单号
      createTimeStart: createTime.value[0], // 开始日期
      createTimeEnd:  createTime.value[1], // 结束日期
      page: 1,
      pageSize: 10
    }
  })
  let smServeStatusList = ref([
    { key: '未到店', value: 0 },
    { key: '已到店', value: 1 },
    { key: '服务中', value: 2 },
    { key: '服务结束', value: 3 },
    { key: '取消', value: 4 }
  ])
  let statusList = ref([
    { key: '待确认', value: 0 },
    { key: '已确认', value: 1 },
    { key: '已取消', value: 2 }
  ])
  function timeChange(value) {
    if(value) {
      queryParams.data.createTimeStart = value[0]
      queryParams.data.createTimeEnd = value[1]
    } else {
      queryParams.data.createTimeStart = ''
      queryParams.data.createTimeEnd = ''
    }
  }
  // table
  let tableLoading = ref(false)
  let appointmentList = ref([])
  let total = ref(0)
  function getTabList() {
    queryParams.data.shopIds = searchShopValue.value
    tableLoading.value = true
    appointmentPageList(queryParams.data).then(res => {
      tableLoading.value = false
      appointmentList.value = res.items
      total.value = res.totalCount
    }).catch(() => {
      tableLoading.value = false
    })
  }
  // 级联选择初始化
  let refreshItem = ref(0)
  function cascaderInit() {
    refreshItem.value++
  }
  function resetTable() {
    createTime.value = ''
    queryParams.data.linkMobile = ''
    queryParams.data.memberName = ''
    queryParams.data.memberId = ''
    queryParams.data.memberPhone = ''
    queryParams.data.appointmentId = ''
    queryParams.data.status = null
    queryParams.data.smServeStatus = null
    searchShopValue.value = []
    shopValue.value = []
    queryParams.data.createTimeStart = ''
    queryParams.data.createTimeEnd = ''
    queryParams.data.page = 1
    queryParams.data.pageSize = 10
    getTabList()
    cascaderInit()
  }
  function formatCreationTime(row) {
    return FullTimeFormat(row.creationTime)
  }
  // 查看/处理
  const router = useRouter()
  function lookDetailFun(row) {
    router.push({ 
      name: "AppointmentDetail", 
      query: {
        id: row.id
      } 
    })
  }
  //导出表格为excel
  function exportExcel() {
    if(!queryParams.data.createTimeStart || !queryParams.data.createTimeEnd) return ElMessage.warning({
      message: '筛选日期不能为空',
      duration: 2000
    })
    exportData()
  };
  function exportData() {
    let data = {
      linkMobile: queryParams.data.linkMobile,
      memberId: queryParams.data.memberId,
      memberName: queryParams.data.memberName,
      memberPhone: queryParams.data.memberPhone,
      status: queryParams.data.status,
      smServeStatus: queryParams.data.smServeStatus,
      appointmentId: queryParams.data.appointmentId,
      shopIds: queryParams.data.shopIds,
      createTimeStart: queryParams.data.createTimeStart,
      createTimeEnd: queryParams.data.createTimeEnd
    }
    returnAppointmentPageList(data).then((res) => {
      if(!res.eValues) return ElMessage.warning({
        message: '导出数据为空，请重新进行数据筛选',
        duration: 2000
      })
      let reg = /\\/g
      let replaceAfter = res.eValues.replace(reg, "")
      var jsondata = JSON.parse(replaceAfter)
      let time = new Date().getTime()
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(jsondata)
      XLSX.utils.book_append_sheet(workbook, worksheet, "data")
      XLSX.writeFile(workbook, `预约申请单列表_${time}.xlsx`)
    })
  }
  const shopValue = ref([])
  const searchShopValue = ref([])
  const options = ref([]) // 城市门店 树

  const props = {
    multiple: true
  }
  function handleChange () {
    searchShopValue.value = []
    shopValue.value.forEach(val => {
      searchShopValue.value.push(val[val.length - 1])
    })
  }
  function getShopTree() {
    shopDetail().then(res => {
      options.value = res.data.shopTree
    })
  }
</script>

<style lang="scss" scoped>
.exportBox {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
::v-deep {
  .paginationBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next {
      padding: 8px !important;
    }
  }
}
</style>